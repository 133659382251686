<template>
    <the-navigation />
    <main class="main"><router-view></router-view></main>
</template>

<script>
import TheNavigation from './components/TheNavigation.vue';
export default {
    components: {
        TheNavigation
    }
}
</script>

<style>
@import '@/styles/styles.scss';

.main {
    background-color: black;
    border: solid 12px white;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    height: 100%;
}
</style>