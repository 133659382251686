<template>
    <nav class="navigation" v-if="this.isVisible">
        <router-link class="router-link" to="/">Home</router-link>
        <router-link class="router-link" to="/color-matrix">Color Matrix</router-link>
    </nav>
</template>

<script>
    export default {
        data: () => {
        return {
            isVisible: false
        };
    }
    }
</script>

<style lang="scss">
    .navigation {
        padding: 12px;
        background: white;
        display: flex;
        z-index: 999;

        & > * + * {
            margin-left: 12px;
        }
    }
</style>