import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/views/HomePage.vue'

//https://vueschool.io/lessons/lazy-loading-routes-vue-cli-only
const routes = [
    {path: '/', name:'HomePage', component: HomePage},
    {path: '/color-matrix', name:'ColorMatrix', component: ()=>import(/* webpackChunkName: 'ColorMatrix' */'@/views/ColorMatrix.vue')}
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router